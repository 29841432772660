<template>
  <q-btn
    flat
    no-caps
    v-bind="$attrs"
    v-on="$listeners"
    :ripple="false"
    class="app-link-btn"
  >
    <q-tooltip v-if="tooltip && !$attrs.disable">
      {{tooltip}}
    </q-tooltip>

    <slot></slot>
  </q-btn>
</template>

<script>
export default {
  name: 'LinkBtn',
  props: {
    tooltip: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
.app-link-btn {
  font-weight: 400;
  text-decoration: underline;
}
</style>
