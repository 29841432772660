









































import Vue, { VueConstructor } from 'vue';
import FormCard from '@/components/card/FormCard.vue';
import FieldPassword from '@/components/fields/FieldPassword.vue';
import FieldEmail from '@/components/fields/FieldEmail.vue';
import ApiError from '@/components/ApiError.vue';
import formSubmit from '@/mixins/formSubmit';
import Btn from '@/components/buttons/Btn.vue';
import LinkBtn from '@/components/buttons/LinkBtn.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'Login',
  components: {
    LinkBtn,
    Btn,
    ApiError,
    FormCard,
    FieldEmail,
    FieldPassword,
  },
  mixins: [formSubmit],
  data() {
    return {
      model: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    async onSubmit() {
      await this.submit({
        callback: () => this.$auth.login(this.model),
      }, this.$refs.form);

      if (this.hasError) {
        return;
      }

      await this.$store.dispatch('init');
      if (this.$route.query.from) {
        await this.$router.replace(this.$route.query.from as string);
        return;
      }
      await this.$router.replace('/');
    },
  },
});
