<template>
  <div class="row justify-center">
    <q-card>
      <q-card-section class="text-center q-mt-lg">
        <img
          class="error-icon"
          alt="Obashi Platform"
          src="@/assets/images/error.jpeg"
        />
      </q-card-section>
      <q-card-section class="text-center q-px-xl">
        <h4 class="q-my-sm">Oops! The page you are looking for does not exist.</h4>
        <q-btn
          class="q-ma-sm"
          color="amber"
          label="Go back to the last visited page"
          @click="goBack"
        >
        </q-btn>
        <q-btn
          class="q-ma-sm"
          color="primary"
          to="/"
          label="Go to the homepage"
        >
        </q-btn>
      </q-card-section>
    </q-card>
  </div>
</template>
<script>

export default {
  name: 'NotFound',
  methods: {
    goBack() {
      this.$router.go(-2);
    },
  },
};
</script>
<style lang="scss">
.error-icon {
  width: 120px;
  border-radius: 5px;
}
</style>
