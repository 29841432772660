









































import Vue from 'vue';
import Password from '@/validation/password';
import ListTooltip from '@/components/list/ListTooltip.vue';

export default Vue.extend({
  name: 'FieldPassword',
  components: { ListTooltip },
  props: {
    value: {
      type: String,
      required: true,
    },
    autocomplete: {
      type: String,
      default: 'current-password',
    },
    errorMessage: String,
    shouldNotMatch: String,
    shouldMatch: String,
  },
  data() {
    const name = this.$attrs.label || '';

    return {
      isPasswordField: true,
      capsLockOn: false,
      validator: new Password(name),
      apiError: '',
    };
  },
  computed: {
    localAutocomplete(): string {
      if (this.localType === 'password') {
        return this.autocomplete;
      }
      return 'off';
    },
    localType(): string {
      return this.isPasswordField ? 'password' : 'text';
    },
  },
  watch: {
    errorMessage(newVal) {
      this.apiError = newVal;
    },
    shouldNotMatch() {
      if (this.value) {
        (this.$refs.input as any).validate();
      }
    },
    shouldMatch() {
      if (this.value) {
        (this.$refs.input as any).validate();
      }
    },
  },
  methods: {
    changeInputType() {
      this.isPasswordField = !this.isPasswordField;
    },
    validation(text: string) {
      const isValid = this.validator.isValid(text);
      if (typeof isValid === 'string') {
        return isValid;
      }

      if (this.shouldMatch && text !== this.shouldMatch) {
        return this.validator.shouldMatchError;
      }

      if (this.shouldNotMatch && text === this.shouldNotMatch) {
        return this.validator.shouldNotMatchError;
      }

      return true;
    },
    checkCapsLock(event: KeyboardEvent) {
      if (event.getModifierState) {
        this.capsLockOn = event.getModifierState('CapsLock');
      }
      this.apiError = '';
      (this.$refs.input as any).validate();
    },
  },
});
