<template>
  <div>
    <h2 class="text-center q-mb-sm">
      {{ title|capitalizeAllWords }}
    </h2>
    <h4 v-if="description" class="q-mt-md q-mb-lg text-center">
      {{ description|capitalizeFirstWord }}
    </h4>
    <div class="row cla">
      <div class="col-4 offset-4">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: String,
  },
  computed: {
    hasActionsSlot() {
      return !!this.$slots.actions;
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
