<template>
  <q-input
    v-bind="$attrs"
    v-on="$listeners"
    dense
    outlined
    no-error-icon
    :value="value"
    :error="!!errorMessage"
    :error-message="errorMessage"
    :rules="[ validation ]"
    lazy-rules="ondemand"
    type="email"
    class="test--field-email"
    label="Email"
    autocomplete="email"
  >
    <template #after>
      <slot name="after"></slot>
    </template>
  </q-input>
</template>

<script>
import emailValidation from '@/validation/email';

export default {
  name: 'FieldEmail',
  props: {
    value: {
      type: String,
      required: true,
    },
    errorMessage: String,
    required: Boolean,
  },
  methods: {
    validation(val) {
      if (this.required) {
        return emailValidation.isRequired(val);
      }
      return emailValidation.isValid(val);
    },
  },
};
</script>
