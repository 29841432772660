import { Validation } from '@/validation/validation';

class Password extends Validation {
  // https://stackoverflow.com/questions/19605150
  // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
  private readonly passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
  private readonly minLength = 8;
  private readonly name: string = 'Password';
  public readonly shouldMatchError = 'The new passwords you have entered do not match. Please try again.';
  public readonly shouldNotMatchError = 'You can\'t use the current password. Please choose a different one.';

  constructor(name?: string) {
    super();
    // customize the error message
    if (name) this.name = name;
  }

  get isShortError() {
    return `${this.name} must be at least ${this.minLength} characters`;
  }

  get isValidError() {
    return `${this.name} must contain at least one uppercase letter, one lowercase letter and one number`;
  }

  get isRequiredError() {
    return `${this.name} is required`;
  }

  isValid(text: string): boolean | string {
    if (!text) {
      return this.isRequiredError;
    }

    if (text.length < this.minLength) {
      return this.isShortError;
    }

    if (!this.passwordRegex.test(text)) {
      return this.isValidError;
    }

    return true;
  }
}

export default Password;
